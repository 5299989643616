<template>
  <div>
    
    <!-- <b-navbar toggleable="lg" type="light" variant="light" fixed="top">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <div class="navbar-left">
          <img :src="logo" alt="Logo" class="navbar-image">
          <b-navbar-nav>
            <b-nav-item to="/" exact>Seznam</b-nav-item>
            <b-nav-item @click="goBack">Karta</b-nav-item>
          </b-navbar-nav>
        </div>
      </b-collapse>
    </b-navbar> -->
    
    <div class="content-wrapper">
    
    <table class="full-width-table">
      <tr>
        <td>IC Klinika Brno s.r.o 
          <br>
          Bratří Čapků 14
          <br>
          602 00 Brno
        </td>
        <td><img :src="logo" alt="Logo" class="navbar-image"></td>
        <td>
          Provozovna:
          <br>
          IC Klinika Brno
          <br>
          Bulharská 29, 612 00 Brno
        </td>
      </tr>
    </table> 

    <table class="full-width-table">
      <tr>
        <td>
          <h3>INFORMOVANÝ SOUHLAS S POSKYTNUTÍM INFORMACÍ O ZDRAVOTNÍM STAVU</h3>
        </td>
      </tr>
    </table>  

    <table class="full-width-table-framed">
      <tr>
        <td class="left-align">Jméno, příjmení a titul pacienta: 
          <br>
          <input type="text" v-model="patient.name" class="no-border" placeholder="Jméno, příjmení a titul" />
        </td>
        <td class="left-align">Rodné číslo/číslo pojištěnce (cizinci):
          <br>
          <input type="text" v-model="patient.pid" class="no-border" placeholder="Rodné číslo/číslo pojištěnce" />
        </td>
      </tr>
      <tr>
        <td class="left-align">Bydliště-město, ulice, PSČ: 
          <br>
          <input type="text" v-model="patient.address" class="no-border" placeholder="Bydliště-město, ulice, PSČ" />
        </td>
        <td class="left-align">Zdravotní pojišťovna:
          <br>
          <input type="text" v-model="patient.hi" class="no-border" placeholder="Zdravotní pojišťovna" />
        </td>
      </tr>
      <tr>
        <td class="left-align">Telefon: 
          <br>
          <input type="text" v-model="patient.phone" class="no-border" placeholder="Telefon" />
        </td>
        <td class="left-align">Email:
          <br>
          <input type="text" v-model="patient.email" class="no-border" placeholder="Email" />
        </td>
      </tr>
      <tr>
        <td colspan="2" class="left-align">Jméno zákoného zástupce(pouze u dětí a nesvéprávné osoby): 
          <br>
          <input type="text" class="no-border" placeholder="zákonný zástupce" />
        </td>
       
      </tr>
    </table> 

    <br>
    <p class="left-align">
    Prohlašuji, že souhlasím s využitím a zpracováním mého jména, příjmení a titulu, rodného čísla, čísla pojištěnce,
    bydliště, kódu zdravotní pojišťovny, e-mailové adresy a telefonního kontaktu v souladu s příslušnými
    právními předpisy EU.
    </p>
    <p class="left-align">
    Osoby, kterým mohou být poskytovány informace o mém zdravotním stavu
    <br>
    Prohlašuji, že souhlasím s poskytováním informací o mém zdravotním stavu v plném rozsahu (včetně práva nahlížet do zdravotnické dokumentace vedené o mé osobě a práva na pořízení výpisů, opisů nebo kopií dokumentů shromážděných v mé zdravotnické dokumentaci) těmto osobám:
    </p>

   
    <div class="left-align">
    <label class="checkbox-label">
      ANO
      <input type="checkbox" v-model="patient.passinfo" true-value="1" false-value="0"/>
    </label>
    
    <label class="checkbox-label">
      NE
      <input type="checkbox" :checked="patient.passinfo == 0"  @input="togglePassInfo" />
    </label>
    </div>


    <table v-if="patient.passinfo == 1" class="full-width-table-framed">
      <tr>
        <td class="left-align">Osoba č.1: 
          <br>
          Jméno a příjmení:<input type="text" v-model="patient.infperson1" class="no-border" placeholder="Jméno, příjmení" />
          Vztah:<input type="text" class="no-border" placeholder="vztah" />
          Kontakt, telefon:<input type="text" class="no-border" placeholder="kontakt" />
        </td>
        <td class="left-align">Osoba č.2:
          <br>
          Jméno a příjmení:<input type="text" v-model="patient.infperson2" class="no-border" placeholder="Jméno, příjmení" />
          Vztah:<input type="text" class="no-border" placeholder="vztah" />
          Kontakt, telefon:<input type="text" class="no-border" placeholder="kontakt" />
        </td>
      </tr>
    </table>  

    <br>
    <p class="left-align">
    Forma podávání informací o Vás
    <br>
    Souhlasím s předáváním informací souvisejících s mým zdravotním stavem prostřednictvím emailu, pošty ä telefonicky pomocí výše uvedených kontaktních údajů s uvedením hesla pro komunikaci.
    </p>

    <br>
    <p class="left-align">
    HESLO: **** poslední 4 číslice čísla pojištěnce
    </p>

    <br>
    <p class="left-align">
    ICK tímto výslovně pacienta upozorňuje, že takto postupuje s jeho souhlasem a nenese žádnou odpovědnost za
    případné chybné, neúplné či jinak nežádoucí doručení či nakládání s těmito informacemi, jelikož ICK nemůže tyto
    způsoby předání informací zcela zabezpečit proti úniku informací neoprávněné osobě. Současně pacient bere na
    vědomí, že uvedené nemá vliv na možnost uplatnění jeho práv, které pro něj vyplývají z předpisů GDPR.
    </p>

    <br>
    
    <table class="full-width-table-sign">
      <tr>
        <td>
          <input type="text" v-model="patient.approveDate" class="no-border" placeholder="datum" />
        </td>
        <td></td>
        <td></td>
      </tr> 
    </table> 
    <table class="full-width-table-sign2">
      <tr>
        <td>datum
        </td>
        <td>podpis pacienta
        </td>
        <td>podpis osoby, která informace získala 
        </td>
      </tr>
    </table> 


    <table class="footer-table">
      <tr>
        <td>
          &#9993; info@icklinika.cz
          <br>
          &#9742; +420 541 420 770-1
        </td>
        <td>
          &#127758; https://www.icklinika.cz
          <br>
          IČO: 268 85 361
        </td>
        <td>
          &nbsp;
        </td>
      </tr> 
    </table> 
    

    </div>
    
</div>
</template>
  
  <script>
  import { getIcUserById } from '../services/api'; // Adjust import based on your file structure

  export default {
    name: 'AgreementPage',
    data() {
    return {
      user: null,
      patient: null,
      userName: "",
      logo: require('@/assets/logo.png'),
    };
  },
    methods: {
    async fetchUserDetails() {
      try {
        console.log("xxxx");
        console.log(this.$route.params.patient);
        const response = await getIcUserById(this.$route.params.patient.id); 
        this.user = response.data.data;  
        this.userName = this.user.name;
       
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    },
    goBack() {
      this.$router.go(-1); // Navigates back to the previous route
    },
    togglePassInfo(event) {
      // Set passinfo to the opposite value of the second checkbox
      this.patient.passinfo = !event.target.checked; // Invert the value
    },
   
 
    
  },
  created() {
   // this.fetchUserDetails(); // Fetch user details when the component is created
    if (this.$route.params.patient) {
    this.patient = JSON.parse(this.$route.params.patient);
    console.log(this.patient.passinfo);
  }

  },
  
};
 
 
  </script>

<style>
.navbar-left {
  display: flex; /* Aligns the image and links horizontally */
  align-items: center; /* Centers items vertically */
}

.navbar-image {
  height: 40px; /* Set the desired height of the image */
  margin-right: 15px; /* Adds space between the image and the nav items */
}

.content {
  margin-top: 56px; /* Adjust margin for the content to avoid overlap with the fixed navbar */
}

.content-wrapper {
  width: 80%;
  margin: 0 auto; /* Centers the div */
}

.full-width-table {
  width: 100%; /* Make the table full width */
  border-collapse: collapse; /* Optional: To remove spaces between cells */
}

.full-width-table td {
  padding: 8px; /* Optional: Adds some padding inside cells */
}

.full-width-table-framed {
  width: 100%; /* Make the table full width */
  border-collapse: collapse; /* Optional: To remove spaces between cells */
}

.full-width-table-framed td {
  border: 1px solid #ddd;  
  padding: 8px; /* Optional: Adds some padding inside cells */
}

.left-align {
  text-align: left;
}

.no-border {
  border: none;
  outline: none; /* Optional: Remove the outline on focus */
  width: 100%; /* Optional: Make the input take full width */
  padding: 5px;   /* Optional: Adjust padding */
}

.checkbox-label {
  margin-bottom: 10px; /* Adjust the value as needed for spacing */
  margin-right: 20px;
}

.full-width-table-sign {
    width: 100%; /* Ensure the table takes full width */
    table-layout: fixed; /* Use fixed layout */
    border: 1px solid black; /* Optional: border for visual clarity */
  }
.full-width-table-sign td {
    height: 120px;  
    width: 33.33%; /* Equal width for each column */
    border: 1px solid #ddd;  
    text-align: center; 
    vertical-align: middle; 
    padding: 10px; 
  }
 .full-width-table-sign2 {
    width: 100%; /* Ensure the table takes full width */
    table-layout: fixed; /* Use fixed layout */
 }
.full-width-table-sign2 td {
  width: 33.33%; /* Equal width for each column */
  text-align: center;
  padding: 10px; /* Optional: add some padding */
 }

 .footer-table {
    width: 100%; /* Ensure the table takes full width */
    table-layout: fixed; /* Use fixed layout */
 }
.footer-table td {
  width: 33.33%; /* Equal width for each column */
  text-align: left;
  padding: 10px; /* Optional: add some padding */
  font-size: 14px;         
 }

</style>