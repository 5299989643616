

<template>
  <div class="modal-container" v-if="isVisible">
    <div class="modal-body">
      <span class="modal-close" @click="emitClose">🗙</span>
      <h2>{{ message }}</h2>
      <div class="modal-action">
        <button class="modal-button" @click="emitOK">Confirm</button>
        <button class="modal-button" @click="emitClose">Cancel</button>
      </div>
    </div>
  </div>
</template>


<!-- <template>
  <div class="modal fade show" tabindex="-1" style="display: block;" v-if="isVisible">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn-close" @click="emitClose" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ message }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="emitClose">Close</button>
        </div>
      </div>
    </div>
  </div>
</template> -->


<script>
 
export default {
  name: "ModalDialog",
  
  props: {
   
    isVisible: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: 'Modal Title'
    },
    message: {
      type: String,
      default: 'Modal Message'
    },
    response: {
      type: String,
      default: 'Modal Message'
    }
  },

  data() {
    return {
  //    isVisible: true,
    };
  },
  created: function() {

    
  },
  methods: {
    emitClose() {
     
      //this.$emit('update:isVisible', false);
      this.$emit('update:response', "cancel");
    },

    
    emitOK() {
     
      this.$emit('update:response', "ok");
    }
  
}
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 1200px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #0a271a;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
  color: #f6f8f7;
  border-radius: 2px;
  padding: 5px 15px;
  background-color: rgb(196, 11, 72);
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-body {
  margin: 20px 0;
  max-height: 400px;
  /* https://jsfiddle.net/achillesp/1rpf6fme/ */
  overflow-y: auto;

  max-width: 1200px;
  overflow-x: auto;
}
.res-table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  background-color: #ffffff;
}
.table_wrapper {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}


.modal-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: #cececeb5;
    }
    .modal-body {
      background-color: #fff;
      border: 2px solid #74a2cf;
      border-radius: 10px;
      text-align: center;
      padding: 20px 40px;
      min-width: 250px;
      display: flex;
      flex-direction: column;
    }
    .modal-action {
      display: flex;
      flex-direction: row;
      gap: 40px;
      justify-content: center;
    }
    .modal-button {
      cursor: pointer;
      height: 30px;
      padding: 0 25px;
      border: 2px solid #74a2cf;
      border-radius: 5px;
      background-color: #80b2e4;
      color: #fff;
    }
    .modal-close {
      cursor: pointer;
      position: relative;
      align-self: end;
      right: -33px;
      top: -17px;
    }
  </style>
