<template>
    <div>
      <NavBar
        v-bind:items="[
        //   {
        //     text: this.fullEdit ? 'Nastavení' : 'Seznam',
        //     to: { name: this.fullEdit ? 'AccountDetail' : 'AccountDetail' }
        //   },
        {
            text: 'Seznam',
            to: { name: 'Home' }
        },  
        {
            text: this.fullEdit ? 'účet' : 'profil',
            to: { name: 'AccountDetail' }
          }
        ]"
      />
      <div class="container p-5">
        <h1 class="mb-3">{{ accountDetail.name }}</h1>
  
        <div>
          <b-overlay :show="loadingUserForm" rounded="sm">
            <b-form class="col-6">
              <b-form-group
                id="input-group-id"
                label="ID:"
                label-for="input-id"
                label-cols="4"
              >
                <b-form-input id="input-id" v-model="accountDetail.id" disabled />
              </b-form-group>
  
              <b-form-group
                id="input-group-email"
                label="Email:"
                label-for="input-email"
                label-cols="4"
              >
                <b-form-input
                  id="input-email"
                  v-model="accountDetail.email"
                  required
                  placeholder="Zadejte email"
                />
              </b-form-group>
  
              <b-form-group
                id="input-group-name"
                label="Jméno:"
                label-for="input-name"
                label-cols="4"
              >
                <b-form-input
                  id="input-name"
                  v-model="accountDetail.name"
                  required
                  placeholder="Zadejte jméno"
                ></b-form-input>
              </b-form-group>
  
              <b-form-group
                id="input-group-access"
                label="Popis"
                label-for="input-access"
                label-cols="4"
              >
                <b-form-input
                  id="input-access"
                  v-model="accountDetail.access"
                  placeholder=""
                ></b-form-input>
              </b-form-group>
  
              <b-form-group
                id="input-group-role"
                label="Role:"
                label-for="input-role"
                label-cols="4"
              >
                <b-form-select
                  id="input-role"
                  v-model="accountDetail.role"
                  :options="this.level == 1 ? roles : roles2"
                  :disabled="this.fullEdit == false"
                ></b-form-select>
              </b-form-group>
  
              <!-- <b-form-group
                id="input-group-terms-agreed"
                label="Souhlas s obchodními podmínkami"
                label-for="input-terms-agreed"
                label-cols="4"
              >
                <b-form-checkbox
                  :disabled="this.fullEdit == false || this.scopeEdit == false"
                  id="input-terms-agreed"
                  v-model="termsAgreed"
                ></b-form-checkbox>
              </b-form-group>
   -->
              <b-form-group
                id="input-group-expiration"
                label="Omezení platnosti"
                label-for="input-expiration"
                label-cols="4"
              >
                <b-form-input
                  :disabled="this.fullEdit == false || this.scopeEdit == false"
                  id="input-expiration"
                  v-model="accountDetail.expiration"
                  type="date"
                  autocomplete="off"
                  locale="en"
                ></b-form-input>
              </b-form-group>
  
              <b-form-group
                id="input-group-pass"
                label="Password:"
                label-for="input-pass"
                label-cols="4"
              >
                <b-form-input
                  id="inputpass"
                  type="password"
                  v-model="passvalue"
                ></b-form-input>
              </b-form-group>
  
              <b-button
                type="submit"
                variant="outline-primary"
                @click="saveAccount"
                >Uložit</b-button
              >
              &nbsp;
              <b-button
                variant="danger"
                @click="deleteAccount"
                v-if="this.fullEdit == true"
                >Smazat</b-button
              >
              &nbsp;
              <b-button variant="danger" @click="changePassword"
                >Upravit heslo</b-button
              >
            </b-form>
          </b-overlay>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import NavBar from "../components/navbar.vue";
  
  export default {
    name: "AccountDetail",
    components: { NavBar },
    methods: {
      saveAccount() {
        //console.log(this.accountDetail);
        this.loadingUserForm = true;
        this.axios
          .post("api", {
            method: "saveAccount",
            data: this.accountDetail
          })
          .then(response => {
            //console.log(response.data);
            this.accountDetail = response.data.data;
            this.$store.commit("mutate", {
              property: "selectedAccount",
              with: this.accountDetail
            });
            this.loadingUserForm = false;
            this.$router.go(-1);
          });
      },
  
      deleteAccount() {
        if (window.confirm("Vybraný účet bude vymazán. Pokračovat?")) {
          this.axios
            .post("api", {
              method: "deleteAccount",
              data: this.accountDetail
            })
            .then(response => {
              console.log(response.data);
              this.$router.go(-1);
            });
        }
      },
  
      changePassword() {
        if (this.passvalue.length < 3) {
          window.alert("Prázdné nebo krátké heslo.");
        } else {
          this.axios
            .post("api", {
              method: "savePassword",
              id: this.accountDetail.id,
              data: this.passvalue
            })
            .then(() => {
              //console.log(response.data);
              window.alert("Heslo bylo upraveno.");
            });
        }
      }
    },
  
    watch: {
      termsAgreed(val) {
        if (!val) this.accountDetail.terms_agreed = 0;
      }
    },
  
    created: function() {
      //console.log(this.$route.params.id);
      this.loadingUserForm = true;
      this.axios
        .post("api", {
          method: "getAccount",
          id: this.$route.params.id
        })
        .then(response => {
          this.accountDetail = response.data.data;
          this.loadingUserForm = false;
  
          if (!this.scopeEdit) {
            this.accountDetail.scopekey = this.$store.state.account.scopekey;
            this.accountDetail.scopevalue = this.$store.state.account.scopevalue;
          }
  
          this.termsAgreed =
            Number(this.accountDetail.terms_agreed) >=
            Number(this.$store.state.requiredAgreementVersion);
  
          switch (this.$store.state.account.role) {
            case "coordinator":
              this.level = 2;
              break;
          }
        });
    },
  
    data: function () {
      return {
        fullEdit: !this.$route.params.profile,
        loadingUserForm: false,
        accountDetail: {},
        canEdit: true,
        passvalue: "",
        roles: [
          { text: "-- Vyberte -- ", value: null },
          "superuser",
          "admin",
          "examinator",
          "editor",
          "visitor",
          "coordinator"
        ],
        roles2: [
          { text: "-- Vyberte -- ", value: null },
          "examinator",
          "editor",
          "visitor"
        ],
        sortBy: "id",
        sortDesc: true,
        scopeEdit:
          this.$store.state.account.scopekey == null ||
          this.$store.state.account.scopekey == "",
        level: 1,
        termsAgreed: false
      };
    }
  };
  </script>
  
  <style></style>
  