Copy
<template>
  <div>

    <NavBar
      v-bind:items="[
        {
          text: 'Seznam pacientů',
          to: { name: 'home' },
        },
      ]"
    />
    
    <div class="content-wrapper">

    <br>

    <div class="container mt-4">
      <!-- <h1>Seznam pacientů</h1> -->

      <div class="button-container d-flex justify-content-end mt-3">
        <!-- <button class="btn btn-success mx-2" @click="saveFile">Uložit soubor</button> -->
        <!-- <button class="btn btn-success mx-2" @click="getFiles">Soubory</button> -->
        <button class="btn btn-success mx-2" @click="bulkImport"><i class="bi bi-arrow-up"></i> Import</button>
        <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload"/>
        <input type="file" ref="fileInput2" style="display: none;" @change="handleFileUpload2"/>
      </div>

      <br>

      <div class="search-container mb-3">
        <input
          type="text"
          v-model="searchTerm"
          placeholder="Hledej jméno nebo rodné číslo..."
          class="form-control"
        />
      </div>
     
      <div>
        <!-- <h2 class="mt-4">Items</h2> -->

        <b-table
          :items="filteredItems"
          :fields="fields"
          :per-page="itemsPerPage"
          v-model:current-page="currentPage"
          v-model:sort-by="sortBy"
          v-model:sort-desc="sortDesc"
          @row-clicked="rowClicked"
          class="text-left" 
        >
          <template #cell(id)="data">
            {{ data.item.id }}
          </template>
          <template #cell(name)="data">
            {{ data.item.name }}
          </template>
          <template #cell(pid)="data">
            {{ data.item.pid }}
          </template>
          <template #cell(passinfo)="data">
            <span v-if="data.item.passinfo == 1" class="icon-1">✔️</span>
            <span v-else class="icon-0">❌</span>
          </template>
        </b-table>

        

        <!-- Pagination Controls -->
        <b-pagination
          v-model="currentPage"
          :total-rows="filteredItems.length"
          :per-page="itemsPerPage"
          aria-label="Pagination"
        ></b-pagination>
      </div>
    </div>
    <div v-if="showProgress">
        <progress :value="processedRecords" :max="totalRecords"></progress>
        <p>{{ processedRecords }} out of {{ totalRecords }} records processed.</p>
    </div>
  </div>

  <!-- <h1>Image Display</h1>
  <img id="image" alt="Image" style="max-width: 100%;" />
  <iframe
      v-bind:src="pdfUrl"
      width="100%"
      height="1000px"
      style="border: none;"
    ></iframe> -->

</div>
</template>

<script>
import NavBar from "../components/navbar.vue";
import { setBaseURL, getPeople, addIcUser, getFiles, saveIcFile } from '../services/api'; // Adjust import based on your file structure
import { mapState } from 'vuex';

export default {
  name: 'HomePage',
  components: { NavBar },
  data() {
    return {
      logo: require('@/assets/logo.png'),
      items: [],
      currentPage: 1,
      itemsPerPage: 20, 
      sortBy: 'id', // Default sorting key; can be changed to 'name' to sort by name initially
      sortDesc: false, // Sort order, `false` for ascending
      fields: [
        // { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Jméno', sortable: true },
        { key: 'pid', label: 'Rodné číslo', sortable: true },
        { key: 'address', label: 'Adresa', sortable: true },
        { key: 'hi', label: 'Pojišťovna', sortable: true },
        { key: 'phone', label: 'Telefon', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'passinfo', label: 'Inf', sortable: true },
       // { key: 'infperson1', label: 'osoba 1', sortable: true },
       // { key: 'infperson2', label: 'osoba 2', sortable: true },
       // { key: 'approvedate', label: 'datum', sortable: true },
      ],
      showProgress: false,
      totalRecords: 100,
      processedRecords: 0,
      searchTerm: '', // Add this line for search input
      pdfUrl: "",
      binaryData: null,
    };
  },
  methods: {
    async getFiles() {
      try {
        //const resource = "01_100.pdf";//"01_102.jpg";
        const resource = "01_102.jpg";
        const response = await getFiles(resource);
       // console.log(response.data);
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        //const blob = new Blob([response.data]);
        const imageUrl = URL.createObjectURL(blob);
    //  const imageUrl = URL.createObjectURL(response.data);
        //console.log(blob);
        console.log(imageUrl);

        var ur = "http://www.ickapp.cz/ickdoc/01_102.jpg";

        // Set the blob URL as the image source
       document.getElementById('image').src = ur;
       this.pdfUrl = imageUrl;

       console.log(document.getElementById('image').src);

    
       
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    },
    async fetchItems() {
      try {
        const response = await getPeople();
        console.log("get people");
        console.log(response);
        this.items = response.data.data; // Adjust based on your API response structure
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    },
    bulkImport() {
      // Trigger the file input click
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
    const file = event.target.files[0]; // Get the selected file
    if (file && file.type === "text/plain") { // Check if it is a text file
      const reader = new FileReader();
      
      reader.onload = (e) => {
        const fileContent = e.target.result; // File content in plain text
        console.log('File content:', fileContent);
        // get records and save to DB
          // Split content by line to get each record
          const records = fileContent.split('\n').map(line => line.trim()).filter(line => line); // Remove empty lines

          // Process each record
          const processedRecords = records.map(record => {
              const fields = record.split(';').map(field => field.trim()); // Split fields by semicolon and trim whitespace

              // Check for basic validity (e.g., number of fields, required fields)
              if (fields.length < 2) { // Assuming at least 2 fields are required
                  console.error('Invalid record:', record);
                  return null; // Skip invalid record
              }

              // Map to user object
              return {
                    user: {
                        name: fields[0], // Assuming first field is the user's name
                        pid: fields[1]   // Assuming second field is the user's pid
                    }
                };
          }).filter(record => record !== null); // Remove invalid records

          console.log('Processed records:');
           // Iterate through each processed record and save it
          this.totalRecords = processedRecords.length;
          this.showProgress = true;
          var i = 1;
           processedRecords.forEach(record => {
            //console.log(record);
            if (record) {
                this.processedRecords = i;
                addIcUser(record.user); 
                ++i;
              }
            });
          this.showProgress = false;
          this.fetchItems();
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };

      reader.readAsText(file); // Read the file as text
    } else {
      alert("Please select a valid text file.");
    }
  },

    saveFile() {
      //console.log("0s - click");
      this.$refs.fileInput2.click();
    },
    

    rowClicked(item) {
      console.log(item.id);
      this.$router.push({ name: 'UserDetail', params: { id: item.id } });
    },

    async handleFileUpload2(event) {
    
      const file = event.target.files[0];
      if (file) { 

        const response = await saveIcFile(1, this.removeExtension(file.name), file.name, "soubor");
        console.log("1s");
        //var x =  this.$store.state.account.id;   
        //console.log(x);

        console.log(response);

        // upload file , store.state.config.serverUrl;

        let formData = new FormData();
        formData.append('file', file);

        this.axios.post('https://ickapp.cz/backend/server/uploadfile.php', formData,
        {
          headers: {
          'Content-Type': 'multipart/form-data'
          }
        }
        ).then(function(data){
            console.log(data.data);
        })
        .catch(function(){
          console.log('FAILURE!!');
        });
    }

    else {
      alert("Please select a valid file.");
    }
    },

    onChangeFileUpload(){
      this.file = this.$refs.file.files[0];
    },

    removeExtension(filename) {
      const lastDotIndex = filename.lastIndexOf('.');
      if (lastDotIndex === -1 || lastDotIndex === 0) return filename;
      return filename.substring(0, lastDotIndex);
    },


  },
  computed: {
    ...mapState(['config', 'account']),
    filteredItems() {
    return this.items.filter(item => {
      // Check if the item's name or PID includes the search term (case insensitive)
      const nameMatch = item.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      const pidMatch = item.pid.toString().includes(this.searchTerm);
      return nameMatch || pidMatch;
    });
  },
  },
  created() {
    setBaseURL(this.config.serverUrl);
  },
  mounted() {
    this.fetchItems();
  },
};

</script>

<style>
.navbar-left {
  display: flex; /* Aligns the image and links horizontally */
  align-items: center; /* Centers items vertically */
}

.navbar-image {
  height: 40px; /* Set the desired height of the image */
  margin-right: 15px; /* Adds space between the image and the nav items */
}

.content {
  margin-top: 56px; /* Adjust margin for the content to avoid overlap with the fixed navbar */
}

.content-wrapper {
  width: 80%;
  margin: 0 auto; /* Centers the div */
}

.text-left {
  text-align: left;
}

.text-nav {
  padding: 10px; 
  font-size: 20px;
  display: flex; /* Use flexbox for alignment */
  align-items: center; 
}
.text-nav-active {
  padding: 10px; 
  font-size: 20px;
  font-weight: bold; /* Set text to bold */
  display: flex; /* Use flexbox for alignment */
  align-items: center; 
}
</style>