<template>
    <div>
      <h1>About Page</h1>
      <router-link to="/">Go to Home Page</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutPage',
  };
  </script>