<template>
    <div>
     
        <div class="row" style="background-color: #ededed;">
         <div class="col-12">
         <br>
        <img
          class="rounded mx-auto d-block"
          :src="logo"
          width="100"
        />
        <br />
        <h1 class="h3 mb-3 font-weight-normal">
            Administrace informovaného souhlasu pacienta
        </h1>
        <br>
        </div>
    </div> 
     
      <div class="row">
        <div class="col-12">
          <form class="form-signin" @submit.prevent="tryLogin">
            
            <div v-if="!asPatient">
            <div class="h4 mb-3 font-weight-normal">Přihlášení</div>
            <b-alert variant="danger" :show="loginError">
              {{ loginErrorMsg }}
            </b-alert>
            <label for="inputEmail" class="sr-only">Email</label>
            <input
              type="email"
              id="inputEmail"
              class="form-control"
              placeholder="Email"
              required
              autocomplete="username"
              autofocus
              v-model="email"
            />
            <br>
            <label for="inputPassword" class="sr-only">Heslo</label>
            <input
              type="password"
              id="inputPassword"
              class="form-control"
              placeholder="Heslo"
              required
              autocomplete="current-password"
              v-model="pass"
            />
  
            <button class="btn btn-lg btn-primary btn-block mt-3" type="submit">
              <b-spinner label="Spinning" v-show="loading"></b-spinner>Přihlásit
            </button>

            </div>

            <div v-if="asPatient">
            <div class="h4 mb-3 font-weight-normal">Přihlášení pacienta</div>
            <b-alert variant="danger" :show="loginError">
              {{ loginErrorMsg }}
            </b-alert>
            <label for="patientId" class="sr-only">rodné číslo</label>
            <input
              type="text"
              id="patientId"
              class="form-control"
              v-model="patientId"
              required
              placeholder="zadejte svoje rodné číslo"
            />
  
            <button
              class="btn btn-lg btn-primary btn-block mt-3" type="submit">
                Přihlásit
            </button>

            </div>
          </form>


            <br>
            <div class="button-container d-flex justify-content-center mt-3">
            
            <div v-if = "!asPatient">
              <button
                class="btn btn-sm btn-secondary btn-block mt-3"
                style="opacity: 0.7;" 
                @click="restorePassword"
                >
                Zapomenuté heslo
            </button>
            
            &nbsp;
            </div>

            <button
                class="btn btn-sm btn-secondary btn-block mt-3"
                style="opacity: 0.7;" 
                @click="asPatient = !asPatient;"
                >
                {{ asPatient ? 'Přihlásit jako zdravotník' : 'Přihlásit jako pacient' }}
              </button>
          </div>
  
        </div>
      
      </div>
    
    <!-- footer -->
      <div class="row">
        <div class="col-12">
           <br>
            Build 1.1.2.{{ betaInfo }}
           <br>
           <a href="https://www.icklinika.cz/">Všechna práva vyhrazena © 2024 IC Klinika Brno s.r.o.</a>
        </div>
    </div>

    </div>
  </template>
  
  <script>
  import { setBaseURL, getIcUserByPid } from '../services/api';

  export default {
    name: "LoginPage",
    components: {},
    data: function() {
      return {
        logo: require('@/assets/logo.png'),
        email: "",
        pass: "",
        loading: false,
        loginError: false,
        loginErrorMsg: "",
        roomId: "",
        user_accept: "0",
        terms_of_use_visible: false,
        accountDetail: {},
        asPatient: false,
        patientId: "",
        betaInfo: "",
      };
    },

    mounted() {
      const id = this.$route.query.id;
      if (id) {
        this.$router.push({ name: 'LoginPatient', params: { id: id } });
      } else {
        //this.betaInfo = "?";
      }
    },
  
    methods: {
    
      tryLogin: function() {
        
        if (this.asPatient)
        {
          this.tryLoginAsPatient();
          return;
        }
        
        if (this.loading) return;
        this.loginError = false;
        this.loading = true;
        this.axios
          .post("api", {
            method: "login",
            email: this.email,
            pass: this.pass
          })
          .then(response => {
           // console.log("--- login ----");
           // console.log(response.data);
            if (response.data.status == "OK") {
              this.loginError = false;
              this.$store.commit("mutate", {
                property: "session",
                with: response.data.data.session
              });
              this.$store.commit("mutate", {
                property: "account",
                with: response.data.data.account
              });
              this.$store.commit("mutate", {
                property: "lists",
                with: response.data.data.lists
              });
  
              this.accountDetail = response.data.data.account;

              console.log(this.accountDetail);

              var rightsLevel = 0;
              switch (response.data.data.account.role) {
                
                case "pacient":
                  rightsLevel = 1;
                  break;
                case "user": 
                  rightsLevel = 2;
                  break;
                case "superuser": 
                  rightsLevel = 3;
                  break;
                case "admin":
                  rightsLevel = 10;
                  break;
              }
              this.$store.commit("mutate", {
                property: "rights",
                with: rightsLevel
              });
  
              //check Terms of use and expiration
              //this.terms_of_use_visible = this.accountDetail.terms_agreed == "0";
              //console.log(new Date(this.accountDetail.expiration).getTime());
              if (
                this.accountDetail.expiration != null &&
                this.accountDetail.expiration != "0000-00-00" &&
                new Date(this.accountDetail.expiration).getTime() < Date.now()
              ) {
                const accExpiry = new Date(this.accountDetail.expiration);
                window.alert(
                  "Platnost účtu vypršela dne " +
                    accExpiry.getDate() +
                    "." +
                    (accExpiry.getMonth() + 1) +
                    "." +
                    accExpiry.getFullYear()
                );
              } else {
                if (
                  Number(this.accountDetail.terms_agreed) <
                  this.$store.state.requiredAgreementVersion
                ) {
                  this.terms_of_use_visible = true;
                  window.alert(
                    "Před přihlášením se seznamte s obchodními podmínkami a potvrďte souhlas."
                  );
                } else {
                  this.$router.push("home");
                }
              }
  
              this.$store.roomId = response.data.data.account.roomId;
            } else {
              this.loginError = true;
              this.loginErrorMsg = response.data.error.msg;
            }
  
            this.loading = false;
          })
          .catch(function(error) {
            console.log(error);
          });
      },

      tryLoginAsPatient() {
        if (this.loading) return;
        this.loginError = false;
        this.loading = true;
        this.axios
          .post("api", {
            method: "login",
            email: "pacient@ick.cz",
            pass: '1234'
          })
          .then(response => {
            //console.log("--- login ----");
            //console.log(response.data);
            if (response.data.status == "OK") {
              this.loginError = false;
              this.$store.commit("mutate", {
                property: "session",
                with: response.data.data.session
              });
              this.$store.commit("mutate", {
                property: "account",
                with: response.data.data.account
              });
              this.$store.commit("mutate", {
                property: "lists",
                with: response.data.data.lists
              });
  
              this.accountDetail = response.data.data.account;

              //console.log(this.accountDetail);

              var rightsLevel = 1;
              this.$store.commit("mutate", {
                property: "rights",
                with: rightsLevel
              });

            this.getUser();

            } else {
              this.loginError = true;
              this.loginErrorMsg = response.data.error.msg;
            }
  
            this.loading = false;
          })
          .catch(function(error) {
            //console.log(error);
            window.alert(error);
          });
      },

      async getUser() {
        setBaseURL(this.$store.state.config.serverUrl);
        //console.log("req1");
        const response = await getIcUserByPid(this.patientId); 
        //console.log(response);
        if ((response.data.status == "OK")) { 
          var user = response.data.data;
          if (user) {
            var userId = user.id;
            if (userId) {
              this.$router.push({ name: 'UserDetail', params: { id: userId } });
            } else {
              window.alert("Špatný údaj");
            }
          }
        }
        else
        {
          window.alert("Špatný údaj");
        }
      },
  
      restorePassword() {
        this.axios
          .post("api", {
            method: "restorePassword",
            data: this.email
          })
          .then(response => {
            console.log(response.data);
            window.alert(
              "Proběhla změna hesla. Nové heslo Vám bylo zasláno na " + this.email
            );
          });
      },

    
  
    //   checkTermsOfUse() {
    //     if (this.user_accept == "1") {
    //       //update account
    //       this.accountDetail.terms_agreed = this.$store.state.requiredAgreementVersion;
    //       this.loadingUserForm = true;
    //       this.axios
    //         .post("api", {
    //           method: "saveAccount",
    //           data: this.accountDetail
    //         })
    //         .then(response => {
    //           console.log("saveAccount");
    //           console.log(response.data);
    //         });
    //     }
    //   },
    },
  };
  </script>
  
  <style scoped>
  .form-signin {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: auto;
  }
  
  </style>
  