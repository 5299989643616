<template>
    <div>
      <div>
        <b-navbar toggleable="lg" class="bg-white border-bottom box-shadow">
            <div class="navbar-left">
                <img :src="logo" alt="Logo" class="navbar-image"> 
            
          <b-breadcrumb :items="items"></b-breadcrumb>
         </div> 
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right>
              <!-- Using 'button-content' slot -->
              <template v-slot:button-content>
                {{ userName }}
              </template>
              <b-dropdown-item @click="profile">Profil</b-dropdown-item>
              <b-dropdown-item @click="logout">Odhlásit</b-dropdown-item>
              <b-dropdown-item v-show="settings_enabled" @click="appsettings"
                >Nastavení</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-navbar>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "NavBar",

    props: {
      items: Array
    },
    data() {
      return {
        logo: require('@/assets/logo.png'),
        settings_enabled: false
      };
    },
    components: {},
    computed: {
      userName() {
        return this.$store.state.account.name;
      }
    },
    created: function() {
      /*
      if (!this.$store.state.session) {
        this.$router.push("/");
      }
      */
      //if role == admin, show button Edit users, show users table menu option
      //superuser
      this.settings_enabled =
        this.$store.state.rights == 20 || this.$store.state.rights == 1;
      //console.log("xxxxxx");
      //console.log(this.$store.state.rights);
    },
    methods: {
      logout() {
        this.$store.dispatch("logout");
        this.$router.push("/");
      },
      profile() {
        // this.$router.push("/examinator/profile");
        this.$store.commit("mutate", {
          property: "selectedAccount",
          with: this.$store.state.account.id
        });


        console.log(this.$store.state.account.id);
        this.$router.push({
          name: "AccountDetail",
          params: { id: this.$store.state.account.id }
        });
      },
  
      appsettings() {
        this.$router.push("/appadmin");
      }
    }
  };
  </script>
  
  <style scoped>

.navbar-left {
  display: flex; /* Aligns the image and links horizontally */
  align-items: center; /* Centers items vertically */
}

.navbar-image {
  height: 40px; /* Set the desired height of the image */
  margin-right: 20px;
  margin-bottom: 10px;
}

  .box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  }
  .breadcrumb {
    margin-bottom: 0;
    background-color: white;
  }
  ol,
  ul,
  dl {
    margin: 0;
  }
  </style>
  