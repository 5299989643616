import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { BootstrapVue3 } from 'bootstrap-vue-3';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import store from './store/store.js';
import axios from "axios";
import VueAxios from "vue-axios";
//import Vue from "vue";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(BootstrapVue3);
app.use(VueAxios, axios);

app.axios.defaults.baseURL = store.state.config.serverUrl;

router.beforeEach((to, from, next) => {
  
  if (to.name == "Login" || to.name == "client") {
      next();
      return;
    }
  
    //patient's gate
    if (to.name == "LoginPatient") {
      next();
      return;
    }
  
    // no session established (no correct login), revert to login, deny entry inside
    if (!store.state.session) {
      next({ name: "Login" });
      return;
    }
  
    next();
  });
  
  //router.afterEach(to => {
    // Vue.nextTick(() => {
    //   document.title = to.meta.title || "IckApp";
    // });
  //});

app.mount('#app');
