<template>
  <div>
  
    <NavBar
      v-bind:items="[
        {
          text: 'Seznam pacientů',
          to: { name: 'Home' },
        },
        {
            text: 'Pacient',
            to: { name: 'UserDetail', params: {id: userId} } 
        },
        {
            text: 'Dokument - ' + docName,
            to: { name: 'UserDetail' }
        },
      ]"
    />

   <PictureView  v-if="isImg" :imageUrl="docUrl" ref="embedImg"></PictureView>
 
   <iframe
      v-if="isPdf"
      v-bind:src="docUrl"
      width="100%"
      height="1000px"
      style="border: none;"
   ></iframe>

   <div v-if="isVideo">
    <video
      ref="videoPlayer"
      controls
      width="100%"
    >
      <source :src="docUrl" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
   </div>
     
  </div>
</template>
  
  <script>
  import PictureView from "../components/pictureview.vue";
  import NavBar from "../components/navbar.vue";

  export default {
    name: 'DocumentView',
    components: {
      PictureView,
      NavBar
  },
    data() {
    return {
      docName: '',
      docUrl: '', 
      isImg: false,
      isPdf: false,
      isDoc: false,
      isVideo: false,
      userId: 0,
    };
  },

  methods: {
    async fetchDoc() {
      try {
        console.log(this.$route.params.path); 
        
        var docFile = this.$route.params.path;
        this.docName = this.$route.params.name;
        this.userId = this.$route.params.user;
        this.isImg = docFile.toLowerCase().endsWith('.jpg') || docFile.toLowerCase().endsWith('.jpeg');
        this.isPdf = docFile.toLowerCase().endsWith('.pdf');
        this.isVideo = docFile.toLowerCase().endsWith('.mp4');
        
        //console.log("----- x fetchDoc ------");
        var serverPath = "https://ickapp.cz/backend/server";
        this.docUrl = serverPath  + "/ickdoc/" + this.$route.params.path;
        
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    },
    callChildMethod() {
      // Checking if the child component is mounted and accessible
      if (this.$refs.embedImg) {
        var serverPath = "https://ickapp.cz/backend/server";
      //  this.docUrl = serverPath  + "/ickdoc/" + this.$route.params.path;
        this.$refs.embedImg.loadData(serverPath  + "/ickdoc/" + this.$route.params.path);
      }
    }
  },
  created() {
    this.fetchDoc(); // Fetch user details when the component is created
  },
  mounted() {
    // Call the child method after the parent component has been mounted
    this.callChildMethod();
  },

  
};

// function getSessionAudio() {
//   console.log("get session audio");
//   return new Promise((resolve, reject) => {
//     let reader = new FileReader();
//     reader.onload = () => {
//       console.log("reader result");
//       resolve(reader.result);
//     };
//     console.log(audioBlob);
//     reader.readAsDataURL(audioBlob);
//   });
// }

  </script>