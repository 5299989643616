<template>
    <div
      class="zoom-pan-container"
      @mousedown="startPan"
      @mouseup="endPan"
      @mousemove="pan"
      @mouseleave="endPan"
      @wheel.prevent="zoom"
    >
    <canvas 
        ref="canvas" 
        :width="canvasWidth" 
        :height="canvasHeight" 
        class="responsive-canvas"
      ></canvas>
    </div>
  </template>
  
  <script>
  export default {
    name: "PictureView",
    data() {
      return {
        imageUrl: "",
        scale: 1,
        lastScale: 1,
        offsetX: 0,
        offsetY: 0,
        isPanning: false,
        startX: 0,
        startY: 0,
        canvasWidth: 1800,
        canvasHeight: 1600,
        image: new Image(),
      };
    },
    mounted() {
    // this.image.src = this.imageUrl;
    //  this.image.onload = this.draw;

      //console.log("----- x component mounted ------");
            
      //this.image.src = this.logo;
    },
    methods: {
      loadData(dataUrl) {
        this.image.src = dataUrl;
        this.image.onload = () => {
          console.log("----- loadData ---x---");
          this.canvasWidth = window.innerWidth; // 100% width of the window
        //  console.log(this.canvasWidth);
          this.canvasHeight = (this.image.height / this.image.width) * this.canvasWidth; 
        //  console.log(this.canvasHeight);
          // this.startX = 0;
          // this.startY = 0;
          // this.offsetX = 10;
          // this.offsetY = 10;
          // this.scale = 0.9;

          this.$nextTick(() => {
      this.draw(); // Draw the image after the next DOM update
    });


         };
      
      //  console.log(this.image.src);
      },
      draw() {

        // console.log("scale " + this.scale);
        // console.log("offset " + this.offsetX + ", " + this.offsetY);
        // console.log("img " + this.image.src);

        const ctx = this.$refs.canvas.getContext("2d");
        ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        ctx.save();
        ctx.translate(this.offsetX, this.offsetY);
        ctx.scale(this.scale, this.scale);
        ctx.drawImage(this.image, 0, 0);
        ctx.restore();

       // console.log("--draw");
       // console.log(this.image.src);
       // console.log(this.image.src);
      },
      zoom(event) {
        const wheelDelta = event.deltaY;
        this.lastScale = this.scale;
  
        if (wheelDelta > 0) {
          this.scale = Math.max(this.scale - 0.1, 0.1); // Zoom out
        } else {
          this.scale = Math.min(this.scale + 0.1, 5); // Zoom in
        }
        this.offsetX -= (event.offsetX - this.offsetX) * (this.scale - this.lastScale);
        this.offsetY -= (event.offsetY - this.offsetY) * (this.scale - this.lastScale);
        this.draw();
      },
      startPan(event) {
        this.isPanning = true;
        this.startX = event.clientX - this.offsetX;
        this.startY = event.clientY - this.offsetY;
      },
      endPan() {
        this.isPanning = false;
      },
      pan(event) {
        if (this.isPanning) {
          this.offsetX = event.clientX - this.startX;
          this.offsetY = event.clientY - this.startY;
          this.draw();
        }
      },
    },
  };
  </script>
  
<style scoped>
.zoom-pan-container {
    border: 1px solid #ccc;
    overflow: hidden;
    cursor: grab;
    position: relative;
    width: 100%; /* Ensures the container can resize */
    background-color: #f0f0f0;
}

.responsive-canvas {
    width: 100%; /* Fill the width of the zoom-pan-container */
    height: auto; /* Maintain aspect ratio */
}

.zoom-pan-container:active {
    cursor: grabbing;
}
</style>