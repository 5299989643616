// src/services/api.js
import axios from 'axios';

//const API_URL = 'https://braille.cz/test/server/'; // Replace with your API URL

const apiClient = axios.create({
  baseURL: '', 
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getItems = () => {
  return apiClient.get('/items'); // Adjust endpoint as needed
};

export const createItem = (data) => {
  return apiClient.post('/items', data); // Adjust endpoint as needed
};


export const getPeople = () => {
  return apiClient.post('api', {
        method: "getIcUserList",
        scopekey: "", //this.$store.state.account.scopekey, 
        scopeval: "", //this.$store.state.account.scopevalue,
      }); 
  };

  export const getPeopleEx = () => {
    const dataToSend = { message: 'Hello from Vue!' };
          return axios.post('https://ickapp.cz/api.php', dataToSend, {  //backend/server/api.php
           // withCredentials : true,
             headers: {
               'Content-Type': 'application/json'
             }
          });
        
    // return apiClient.post('api', {
      //     method: "getIcUserList",
      //     scopekey: "", //this.$store.state.account.scopekey, 
      //     scopeval: "", //this.$store.state.account.scopevalue,
      //   }); 
    };

  export const getIcUserById = (id) => {
    return apiClient.post('api', {
        method: "getIcUserDetail",
        id: id,
      }); 
  };

  export const getIcUserByPid = (pid) => {
    return apiClient.post('api', {
        method: "getIcUserByPid",
        pid: pid,
      }); 
  };


  export const saveIcUser = (user) => {
    return apiClient.post('api', {
        method: "saveIcUser",
        user: user,
      }); 
  };

  export const addIcUser = (user) => {
    user.id = null;
    return apiClient.post('api', {
        method: "addIcUser",
        ic_user: user,
      }); 
  };


  export const deleteIcUserById = (id) => {
    return apiClient.post('api', {
        method: "deleteIcUser",
        id: id,
      }); 
  };

  export const getDocuments = (id) => {
    return apiClient.post('api', {
        method: "getIcDocuments",
        id: id, 
      }); 
  };

  export const getFiles = (id) => {
    return apiClient.post('api', {
        method: "getIcFiles",
        id: id, 
        //responseType: 'blob',
      }); 
  };

  export const saveIcFile = (idUser, fileName, filePath, fileType) => {
    return apiClient.post('api', {
        method: "saveIcFile",
        userid: idUser,
        filename: fileName, 
        path: filePath,
        type: fileType,
      }); 
  };

  export const deleteIcFile = (id) => {
    return apiClient.post('api', {
        method: "deleteIcFile",
        id: id,
      }); 
  };

  export const sendIcMail = (id) => {
    return apiClient.post('api', {
        method: "sendIcMail",
        id: id,
      }); 
  };


export const setBaseURL = (serverUrl) => {
  //console.log(serverUrl);
    apiClient.defaults.baseURL = serverUrl;
  };