import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/home.vue';
import About from '../views/about.vue';
import DocumentView from '../views/documentview.vue';
import UserDetail from '../views/userdetail.vue';
import AgreementPage from '../views/agreement.vue';
import LoginPage from '../views/login.vue';
import AccountDetail from '../views/accountdetail.vue';
import LoginPatientPage from '../views/loginpatient.vue';

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },

  {
    path: '/:id?',
    name: 'Login',
    component: LoginPage,
  },

  {
    path: '/patient/:id',
    name: 'LoginPatient',
    component: LoginPatientPage,
  },

  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/userdetail/:id',
    name: 'UserDetail',
    component: UserDetail,
  },
  {
    path: '/documentview/:path/:name/:user',
    name: 'DocumentView',
    component: DocumentView,
  },
  {
    path: '/agreement/:patient',
    name: 'AgreementPage',
    component: AgreementPage,
  },
  {
    path: '/accountdetail/:id',
    name: 'AccountDetail',
    component: AccountDetail,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;